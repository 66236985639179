@media only screen and (min-width: 1200px) {
    .filters-map {
        display: flex;
        height: 131px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;
        border-radius: 4px;
        margin-bottom: 32px;
    }
}