@font-face {
  font-family: 'icon';
  src: url('./f/icons/icons.ttf?m4o0yw') format('truetype'),
    url('./f/icons/icons.woff?m4o0yw') format('woff'),
    url('./f/icons/icons.svg?m4o0yw#icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  overflow: hidden;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow:before {
  content: "\e907";
}
.icon-arrow-down:before {
  content: "\e911";
}
.icon-arrow-left-large:before {
  content: "\e92d";
}
.icon-arrow-left-thin:before {
  content: "\e937";
}
.icon-arrow-right-bold:before {
  content: "\e928";
}
.icon-arrow-right-large:before {
  content: "\e92e";
}
.icon-arrows:before {
  content: "\e912";
}
.icon-back:before {
  content: "\e909";
}
.icon-beach:before {
  content: "\e91c";
}
.icon-business:before {
  content: "\e91d";
}
.icon-call:before {
  content: "\e916";
}
.icon-cards:before {
  content: "\e93b";
}
.icon-check:before {
  content: "\e915";
}
.icon-check-in:before {
  content: "\e931";
}
.icon-check-out:before {
  content: "\e932";
}
.icon-checkmark:before {
  content: "\e902";
}
.icon-children2-3:before {
  content: "\e90b";
}
.icon-close:before {
  content: "\e904";
}
.icon-cup:before {
  content: "\e914";
}
.icon-cutlery:before {
  content: "\e913";
}
.icon-date:before {
  content: "\e908";
}
.icon-double-bed:before {
  content: "\e905";
}
.icon-dropdown:before {
  content: "\e936";
}
.icon-edit:before {
  content: "\e90f";
}
.icon-entertainment:before {
  content: "\e90e";
}
.icon-exclamation:before {
  content: "\e927";
}
.icon-food-drinks:before {
  content: "\e923";
}
.icon-general:before {
  content: "\e921";
}
.icon-guests4:before {
  content: "\e90a";
}
.icon-heart:before {
  content: "\e900";
}
.icon-heart-filled:before {
  content: "\e91e";
}
.icon-hotel:before {
  content: "\e917";
}
.icon-hotel-dark:before {
  content: "\e934";
}
.icon-hotel-services:before {
  content: "\e924";
}
.icon-internet:before {
  content: "\e90d";
}
.icon-invoice:before {
  content: "\e918";
}
.icon-list:before {
  content: "\e93a";
}
.icon-lock:before {
  content: "\e922";
}
.icon-map:before {
  content: "\e929";
}
.icon-minus:before {
  content: "\e938";
}
.icon-nearby:before {
  content: "\e925";
}
.icon-no-image:before {
  content: "\e939";
}
.icon-outdoors:before {
  content: "\e926";
}
.icon-parking:before {
  content: "\e903";
}
.icon-pets:before {
  content: "\e901";
}
.icon-plus:before {
  content: "\e92f";
}
.icon-reception-services:before {
  content: "\e91b";
}
.icon-ribbon-checkmark:before {
  content: "\e90c";
}
.icon-room:before {
  content: "\e91f";
}
.icon-room-facilities:before {
  content: "\e92c";
}
.icon-search:before {
  content: "\e933";
}
.icon-spa-wellness:before {
  content: "\e919";
}
.icon-star:before {
  content: "\e920";
}
.icon-twin-bed:before {
  content: "\e906";
}
.icon-user:before {
  content: "\e930";
}
.icon-voucher:before {
  content: "\e91a";
}
.icon-wellness:before {
  content: "\e910";
}
